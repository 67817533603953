import axios from 'axios'
import * as types from '../mutation-types'

let initialCartItems = JSON.parse(localStorage.getItem('cartItems')) || []

// state
export const state = {
  attribute: null,
  orders: null,
  payments: null,
  products: null,
  categories: null,
  modifiers: null,
  users: null,
  roles: null
}

// getters
export const getters = {
  attributes: state => state.attributes,
  orders: state => state.orders,
  categories: state => state.categories,
  products: state => state.products,
  users: state => state.users,
  roles: state => state.roles,
  modifiers: state => state.modifiers
}

// mutations
export const mutations = {
  [types.ADMIN_FETCH_ALL_ATTRIBUTES] (state, { attributes }) {
    state.attributes = attributes
  },
  [types.ADMIN_FETCH_ALL_CATEGORIES] (state, { categories }) {
    state.categories = categories
  },
  [types.ADMIN_FETCH_ALL_PRODUCTS] (state, { products }) {
    state.products = products
  },
  [types.ADMIN_FETCH_ALL_ORDERS] (state, { orders }) {
    state.orders = orders
  },
  [types.ADMIN_FETCH_ALL_USERS] (state, { users }) {
    state.users = users
  },
  [types.ADMIN_FETCH_ALL_ROLES] (state, { roles }) {
    state.roles = roles
  },
  [types.ADMIN_FETCH_ALL_MODIFIERS] (state, { modifiers }) {
    state.modifiers = modifiers
  }
}

// actions
export const actions = {
  async fetchAttributes ({ commit }) {
    try {
      const { data: attributes } = await axios.get('/api/admin/attributes')
      commit(types.ADMIN_FETCH_ALL_ATTRIBUTES, { attributes: attributes })
    } catch (e) {
      console.log(e)
    }
  },
  async fetchCategories ({ commit }) {
    try {
      const { data: categories } = await axios.get('/api/admin/categories')
      commit(types.ADMIN_FETCH_ALL_CATEGORIES, { categories: categories })
    } catch (e) {
      console.log(e)
    }
  },
  async fetchProducts ({ commit }) {
    try {
      const { data: products } = await axios.get('/api/admin/products')
      commit(types.ADMIN_FETCH_ALL_PRODUCTS, { products: products })
    } catch (e) {
      console.log(e)
    }
  },
  async fetchModifiers ({ commit }) {
    try {
      const { data: modifiers } = await axios.get('/api/admin/modifiers')
      commit(types.ADMIN_FETCH_ALL_MODIFIERS, { modifiers: modifiers })
    } catch (e) {
      console.log(e)
    }
  },
  async fetchOrders ({ commit }) {
    try {
      const { data } = await axios.get('/api/admin/orders')
      commit(types.ADMIN_FETCH_ALL_ORDERS, { orders: data })
    } catch (e) {
      console.log(e)
    }
  },
  async fetchUsers ({ commit }) {
    try {
      const { data } = await axios.get('/api/admin/users')
      commit(types.ADMIN_FETCH_ALL_USERS, { users: data })
    } catch (e) {
      console.log(e)
    }
  },
  async fetchRoles ({ commit }) {
    try {
      const { data } = await axios.get('/api/roles')
      commit(types.ADMIN_FETCH_ALL_ROLES, { roles: data })
    } catch (e) {
      console.log(e)
    }
  },
}
