import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faDotCircle
} from '@fortawesome/free-regular-svg-icons'

import {
  faUser,
  faLock,
  faSignOutAlt,
  faCog,
  faMinus,
  faPlus,
  faClock,
  faAddressBook,
  faAddressCard,
  faUserCircle,
  faUserTie,
  faPhone,
  faSearchLocation,
  faLocationArrow,
  faGlobeAmericas,
  faShoppingBasket,
  faShoppingCart,
  faShoppingBag,
  faTaxi,
  faFile,
  faStickyNote,
  faFileContract,
  faSuitcase, faEdit, faPen, faAlignJustify
} from '@fortawesome/free-solid-svg-icons'

import {
  faGithub
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faUser, faLock, faSignOutAlt, faCog, faGithub, faMinus, faPlus, faClock, faAddressBook,
  faAddressCard, faUserCircle, faUserTie, faPhone, faSearchLocation, faLocationArrow, faGlobeAmericas,
  faDotCircle, faEdit, faPen,
  faShoppingBasket,
  faShoppingCart,
  faShoppingBag,
  faTaxi,
  faSuitcase,
  faStickyNote,
  faFile, faAlignJustify
)

Vue.component('fa', FontAwesomeIcon)

