<template>
  <div class="main-layout">
    <navbar/>

    <div class="container my-md-5 my-4">
      <child/>
      <vue-snotify></vue-snotify>
    </div>
  </div>
</template>

<script>
  import Navbar from '~/components/Navbar'
  import { mapGetters } from 'vuex'

  export default {
    name: 'MainLayout',

    components: {
      Navbar
    },
    mounted () {
      let audio = new Audio('/audio/alert.mp3')
      window.Echo.channel('order-tracker')
        .listen('OrderStatusChanged', (order) => {
          if (this.user) {
            console.log('A new order has been placed')
            if (this.user.role_id == 1 && this.user.id != order.user_id) {
              this.$snotify.success(`A new order has been placed`, `${order.payment_id}`, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true
              })
              audio.play()
            }
          }
        })
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    }
  }
</script>
