try {
  window._ = require('lodash')
  window.$ = window.jQuery = require('jquery')
  require('bootstrap')
} catch (e) {
}
import './axios'
import './fontawesome'

import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

window.Echo = new Echo($.extend({}, window.config.echo, {
  // wsHost: window.location.hostname,
  // wsPort: window.config.wsPort,
  // encrypted: window.config.wsEncrypted,
  // disableStats: true,
  // enabledTransports: ['ws']
}))

moment.tz.setDefault('US/Eastern')

const proto = Object.getPrototypeOf(moment())
/**
 * Set time helper, accepts time string like HH:mm
 */
proto.setTime = function (time) {
  let mObj = moment(time, 'h:mm A')
  return this.set({ 'hour': mObj.hours() > 0 ? mObj.hours() : 0, 'minute': mObj.minutes() > 0 ? mObj.minutes() : 0 })
}


