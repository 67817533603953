import Vue from 'vue'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'
import App from '~/components/App'

import '~/plugins'
import '~/components'

Vue.config.productionTip = false

window.formatCurrency = function (value, treatZeroOrNullAsFree = false) {
  if (treatZeroOrNullAsFree && (!value || parseFloat(value) == 0)) {
    return 'Free'
  }
  return '$' + parseFloat(value).toFixed(2)
}

Vue.filter('currency', function (value, treatZeroOrNullAsFree = false) {
  return window.formatCurrency(value, treatZeroOrNullAsFree)
})

import Snotify from 'vue-snotify' // 1. Import Snotify
// 2. Use Snotify
// You can pass {config, options} as second argument. See the next example or setConfig in [API] section
Vue.use(Snotify)

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

import Swal from 'sweetalert2'

window.SwalEx = function (component, data, swalConfig = {}) {

  let config = {
    html: '<div id="VueSweetAlert2"></div>',
    onBeforeOpen: () => {
      let ComponentClass = Vue.extend(component)
      let instance = new ComponentClass({
        ...data
      })
      instance.$mount()
      document.getElementById('VueSweetAlert2').appendChild(instance.$el)
    }
  }

  return Swal.fire($.extend({}, config, swalConfig))
}
/* eslint-disable no-new */
new Vue({
  i18n,
  store,
  router,
  ...App
})
