// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// shop.js
export const CLEAR_CART = 'CLEAR_CART'
export const UPDATE_CART = 'UPDATE_CART'

export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS'

export const FETCH_ORDER = 'FETCH_ORDER'
export const FETCH_ALL_ORDERS = 'FETCH_ALL_ORDERS'

export const ADD_GRATUITY_TO_CART = 'ADD_GRATUITY_TO_CART'
export const SET_CART_TAX_RATE = 'SET_CART_TAX_RATE'
export const SET_PRODUCT = 'SET_SELECTED_PRODUCT'
//admin.js
export const ADMIN_FETCH_TAG = 'ADMIN_FETCH_TAG'
export const ADMIN_FETCH_ORDER = 'ADMIN_FETCH_ORDER'
export const ADMIN_FETCH_PRODUCT = 'ADMIN_FETCH_PRODUCT'
export const ADMIN_FETCH_ALL_ATTRIBUTES = 'ADMIN_FETCH_ALL_ATTRIBUTES'
export const ADMIN_FETCH_CATEGORY = 'ADMIN_FETCH_CATEGORY'
export const ADMIN_FETCH_ALL_ORDERS = 'ADMIN_FETCH_ALL_ORDERS'
export const ADMIN_FETCH_ALL_PRODUCTS = 'ADMIN_FETCH_ALL_PRODUCTS'
export const ADMIN_FETCH_ALL_CATEGORIES = 'ADMIN_FETCH_ALL_CATEGORIES'
export const ADMIN_FETCH_ALL_USERS = 'ADMIN_FETCH_ALL_USERS'
export let ADMIN_FETCH_ALL_ROLES = 'ADMIN_FETCH_ALL_ROLES'
export let ADMIN_FETCH_ALL_MODIFIERS = 'ADMIN_FETCH_ALL_MODIFIERS'

//settings.js
export const SETTINGS_FETCH_ALL = 'SETTINGS_FETCH_ALL'

