<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white" style="z-index: 1">
    <div class="container">
      <!--      <router-link :to="{ name: user ? 'home' : 'welcome' }" class="navbar-brand">-->
      <!--        {{ appName }}-->
      <!--      </router-link>-->

      <router-link :to="{ name: 'food-menu' }" class="navbar-brand">
        {{ settings.restaurant.name }}
      </router-link>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
              aria-controls="navbarToggler" aria-expanded="false">
        <span class="navbar-toggler-icon"/>
      </button>

      <div id="navbarToggler" class="collapse navbar-collapse">
        <ul class="navbar-nav d-none">
          <locale-dropdown/>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li> -->
        </ul>

        <ul class="navbar-nav mr-auto pt-1">

          <li v-if="user && user.role_id == 1" class="nav-item dropdown pr-2">
            <a class="nav-link dropdown-toggle text-dark"
               href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Manage
            </a>
            <div class="dropdown-menu">
              <router-link :to="{ name: 'attributes.index' }" class="dropdown-item pl-3">
                Attributes
              </router-link>
              <router-link :to="{ name: 'categories.index' }" class="dropdown-item pl-3">
                Categories
              </router-link>
              <router-link :to="{ name: 'products.index' }" class="dropdown-item pl-3">
                Products
              </router-link>
              <router-link :to="{ name: 'modifiers.index' }" class="dropdown-item pl-3">
                Modifiers
              </router-link>
            </div>
          </li>

          <li class="nav-item pr-2" v-if="user && user.role_id == 1">
            <router-link :to="{ name: 'orders.index' }" class="nav-link">
              Orders
            </router-link>
          </li>

          <li class="nav-item pr-2" v-if="user && user.role_id == 1">
            <router-link :to="{ name: 'users.index' }" class="nav-link">
              Users
            </router-link>
          </li>

          <li class="nav-item pr-2" v-if="user && user.role_id == 1">
            <router-link :to="{ name: 'reports' }" class="nav-link">
              Reports
            </router-link>
          </li>

          <li class="nav-item pr-2" v-if="user && user.role_id == 1">
            <router-link :to="{ name: 'settings.system.store' }" class="nav-link">
              System Settings
            </router-link>
          </li>

        </ul>

        <ul class="navbar-nav ml-auto">

          <!-- Authenticated -->
          <li v-if="user" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-dark"
               href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img :src="user.photo_url" class="rounded-circle profile-photo mr-1">
              {{ user.name }}
            </a>
            <div class="dropdown-menu">

              <router-link :to="{ name: 'myorders' }" class="dropdown-item pl-3">
                My Orders
              </router-link>

              <router-link :to="{ name: 'settings.profile' }" class="dropdown-item pl-3">
                Account Settings
              </router-link>

              <div class="dropdown-divider"/>
              <a href="#" class="dropdown-item pl-3" @click.prevent="logout">
                <fa icon="sign-out-alt" fixed-width/>
                {{ $t('logout') }}
              </a>
            </div>
          </li>
          <!-- Guest -->
          <template v-else>
            <li class="nav-item">
              <router-link :to="{ name: 'login' }" class="nav-link" active-class="active">
                {{ $t('login') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'register' }" class="nav-link" active-class="active">
                {{ $t('register') }}
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  import { mapGetters } from 'vuex'
  import LocaleDropdown from './LocaleDropdown'

  export default {
    components: {
      LocaleDropdown
    },
    data () {
      return {}
    },
    computed: mapGetters({
      user: 'auth/user',
      settings: 'shared/settings',
    }),

    methods: {
      async logout () {
        // Log out the user.
        await this.$store.dispatch('auth/logout')

        // Redirect to login.
        this.$router.push({ name: 'login' })
      }
    }
  }
</script>

<style scoped>
  .profile-photo {
    width: 2rem;
    height: 2rem;
    margin: -.375rem 0;
  }
</style>
