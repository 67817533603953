<template>
  <div class="custom-control custom-checkbox d-flex align-content-center">
    <input
      :id="id || name"
      :name="name"
      :checked="internalValue"
      type="checkbox"
      class="custom-control-input"
      @click="handleClick">
    <label :for="id || name" class="custom-control-label my-auto w-100">
      <slot/>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'Checkbox',
    inheritAttrs: true,
    props: {
      id: { type: String, default: null },
      name: { type: String, default: 'checkbox' },
      value: { type: Boolean, default: false },
      checked: { type: Boolean, default: false }
    },

    data: () => ({
      internalValue: false
    }),

    watch: {
      value (val) {
        this.internalValue = val
      },

      checked (val) {
        this.internalValue = val
      },

      internalValue (val, oldVal) {
        if (val !== oldVal) {
          this.$emit('input', val)
        }
      }
    },

    created () {
      this.internalValue = this.value

      if ('checked' in this.$options.propsData) {
        this.internalValue = this.checked
      }
    },

    methods: {
      handleClick (e) {
        this.$emit('click', e)

        if (!e.isPropagationStopped) {
          this.internalValue = e.target.checked
        }
      }
    }
  }
</script>
