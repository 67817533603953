function page (path) {
  return () => import(/* webpackChunkName: '' */ `~/pages/${path}`).then(m => m.default || m)
}

export default [
  {
    path: '/',
    name: 'welcome',
    component: page('index.vue'),
    children: [
      {
        path: '/',
        component: page('shop/products.vue')
      },
      {
        path: '/food-menu',
        name: 'food-menu',
        component: page('shop/products.vue')
      },
      {
        path: '/checkout',
        component: page('shop/checkout.vue'),
      },
      {
        path: '/checkout/:id/success',
        component: page('shop/checkout-success.vue'),
      },
      {
        path: '/contact-us',
        component: page('shop/contact.vue')
      },
    ]
  },
  { path: '/login', name: 'login', component: page('auth/login.vue') },
  { path: '/register', name: 'register', component: page('auth/register.vue') },
  { path: '/password/reset', name: 'password.request', component: page('auth/password/email.vue') },
  { path: '/password/reset/:token', name: 'password.reset', component: page('auth/password/reset.vue') },
  { path: '/email/verify/:id', name: 'verification.verify', component: page('auth/verification/verify.vue') },
  { path: '/email/resend', name: 'verification.resend', component: page('auth/verification/resend.vue') },

  { path: '/home', name: 'home', component: page('home.vue'), redirect: { path: '/' } },
  {
    path: '/settings',
    component: page('settings/index.vue'),
    children: [
      { path: '', redirect: { name: 'settings.profile' } },
      { path: 'profile', name: 'settings.profile', component: page('settings/profile.vue') },
      { path: 'password', name: 'settings.password', component: page('settings/password.vue') }
    ]
  },
  {
    name: 'orders.index',
    path: '/admin/orders',
    component: page('orders/index.vue')
  },
  {
    name: 'attributes.index',
    path: '/admin/attributes',
    component: page('attributes/index.vue')
  },
  {
    name: 'attributes.edit',
    path: '/admin/attributes/:id/edit',
    component: page('attributes/single.vue')
  },
  {
    name: 'attributes.create',
    path: '/admin/attributes/create',
    component: page('attributes/single.vue')
  },
  {
    name: 'categories.index',
    path: '/admin/categories',
    component: page('categories/index.vue')
  },
  {
    name: 'categories.edit',
    path: '/admin/categories/:id/edit',
    component: page('categories/single.vue')
  },
  {
    name: 'categories.create',
    path: '/admin/categories/create',
    component: page('categories/single.vue')
  },
  {
    name: 'products.index',
    path: '/admin/products',
    component: page('products/index.vue')
  },
  {
    name: 'products.edit',
    path: '/admin/products/:id/edit',
    component: page('products/single.vue')
  },
  {
    name: 'products.create',
    path: '/admin/products/create',
    component: page('products/single.vue')
  },
  {
    name: 'modifiers.index',
    path: '/admin/modifiers',
    component: page('modifiers/index.vue')
  },
  {
    name: 'modifiers.edit',
    path: '/admin/modifiers/:id/edit',
    component: page('modifiers/single.vue')
  },
  {
    name: 'modifiers.create',
    path: '/admin/modifiers/create',
    component: page('modifiers/single.vue')
  },
  {
    name: 'users.index',
    path: '/admin/users',
    component: page('users/index.vue')
  },
  {
    name: 'users.edit',
    path: '/admin/users/:id/edit',
    component: page('users/single.vue')
  },
  {
    name: 'users.create',
    path: '/admin/users/create',
    component: page('users/single.vue')
  },
  {
    path: '/admin/reports',
    name: 'reports',
    component: page('reports/index.vue'),
    children: [
      {
        path: '/admin/reports/orders/:start/:end',
        name: 'reports.orders',
        component: page('reports/orders.vue')
      },
      {
        path: '/admin/reports/products/:start/:end',
        name: 'reports.products',
        component: page('reports/products.vue')
      },
      {
        path: '/admin/reports/customers/:start/:end',
        name: 'reports.customers',
        component: page('reports/customers.vue')
      },
      {
        path: '/admin/reports/gratuity/:start/:end',
        name: 'reports.gratuity',
        component: page('reports/gratuity.vue')
      },
    ]
  },
  {
    path: '/admin/settings/',
    name: 'settings.system.index',
    component: page('settings/system/index.vue'),
    children: [
      { path: '', redirect: { name: 'settings.system.store' } },
      { path: 'restaurant', name: 'settings.system.store', component: page('settings/system/restaurant.vue') },
      { path: 'order', name: 'settings.system.order', component: page('settings/system/order.vue') },
      {
        path: 'schedule',
        name: 'settings.system.schedule',
        component: page('settings/system/schedule.vue')
      }

    ]
  },
  { path: '/myorders', name: 'myorders', component: page('shop/myorders.vue') },
  { path: '*', component: page('errors/404.vue') }
]
