<template>
  <div class="card">
    <div v-if="title" class="card-header" style="font-size:16px;font-weight:600;">
      {{ title }}
    </div>

    <div class="card-body">
      <slot/>
    </div>

    <div class="card-footer" v-if="hasFooterSlot">
      <div class="row">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Card',
    props: {
      title: { type: String, default: null }
    },
    computed: {
      hasFooterSlot () {
        return !!this.$slots['footer']
      }
    }
  }
</script>
