import Cookies from 'js-cookie'
import * as types from '../mutation-types'
import axios from 'axios'
import {unitOfTime} from 'moment'

export const state = {
  settings: window.settings, serverTime: moment.utc(), // UTC
}
// getters
export const getters = {
  settings: state => state.settings,
  schedules: state => state.settings.schedules,
  serverTime: state => state.serverTime.format('lll'),
  businessTime: state => state.serverTime.tz('US/Eastern').format('lll'),
  schedule: function (state, getters) {
    if (getters.schedules) {
      let today = state.serverTime.tz('US/Eastern').format('dddd')
      let [schedule] = getters.schedules.filter(s => s.day.toLowerCase().trim() === today.toLowerCase().trim())
      return schedule
    }
    return {}
  },
  isRestaurantOpen: function (state, getters) {

    if (getters.settings) {

      // if (!getters.settings.order.accept_online_orders) {
      //   return false
      // }

      if (getters.schedule) {

        if (!getters.schedule.open) {
          return false
        }

        let now = state.serverTime.tz('US/Eastern')

        let start = moment().setTime(getters.schedule.duration.start)
        let end = moment().setTime(getters.schedule.duration.end)

        if (start.isAfter(end)) {
          end = end.add(1, 'days')
        }

        return now.isBetween(start, end, null, '[)')
      }
    }
    return true
  },
  acceptOnlineOrders: function (state, getters) {
    if (!getters.isRestaurantOpen) {
      return false
    }
    return getters.settings.order.accept_online_orders;
  },
  isWorkingDay: function (state, getters) {
    return getters.schedule.open;
  }
}

// mutations
export const mutations = {
  [types.SETTINGS_FETCH_ALL](state, {settings}) {
    Object.keys(settings).forEach(function (k) {
      if (settings[k].key) {
        state.settings[settings[k].key] = JSON.parse(settings[k].value)
      }
    })
  }
}

// actions
export const actions = {
  async fetchSettings({commit, state}) {
    await axios.get('/api/settings/system').then(response => {
      let {headers, data} = response
      state.serverTime = moment(headers.date).utc()
      commit(types.SETTINGS_FETCH_ALL, {settings: data})
    })
  }
}


