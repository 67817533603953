import axios from 'axios'
import * as types from '../mutation-types'

let initialCartItems = JSON.parse(localStorage.getItem('cartItems')) || []

// state
export const state = {
  orders: null,
  products: null,
  selectedProduct: null,
  cartItems: initialCartItems,
  cartGratuity: 0,
  cartTaxRate: 0,
  cartTotal: initialCartItems.reduce((carry, item) => {
    let modsTotal = 0
    if (item.modifiers) {
      modsTotal = item.modifiers.reduce((c, modifier) => {
        return c + (Number(item.quantity) * Number(modifier.price))
      }, 0)
    }
    return carry + (Number(item.quantity) * Number(item.price)) + modsTotal
  }, 0)
}

// getters
export const getters = {
  orders: state => state.orders,
  products: state => state.products,
  selectedProduct: state => state.selectedProduct,
  cartItems: function (state) {
    const products = _.keyBy(state.products, 'id')
    return state.cartItems.map(s => {
      return Object.assign({}, s, { product: products[s.id] })
    })
  },
  cartTotal: state => state.cartTotal,
  cartTaxRate: state => state.cartTaxRate,
  cartGratuity: state => state.cartGratuity,
  cartTaxTotal: state => (state.cartTotal * state.cartTaxRate / 100),
  cartFinalTotal: (state, getters) => state.cartTotal + state.cartGratuity + getters.cartTaxTotal
}

// mutations
export const mutations = {
  [types.FETCH_ALL_ORDERS] (state, { orders }) {
    state.orders = orders
  },
  [types.FETCH_ALL_PRODUCTS] (state, { products }) {
    state.products = products
  },
  [types.UPDATE_CART] (state, data) {

    let cartItems = state.cartItems

    if (typeof data.quantity === 'undefined' || data.quantity > 0) {
      const record = cartItems.find(value => value.id === data.id)
      if (record) {
        Object.keys(data).forEach(key => {
          record[key] = data[key]
        })
        if (record.modifiers) {
          record.modifiers.forEach(modifier => {
            modifier.quantity = record.quantity
          })
        }
      } else {
        cartItems.push(data)
      }
    }

    if (typeof data.quantity !== 'undefined' && data.quantity < 1) {
      cartItems = cartItems.filter(s => s.id !== data.id)
    }

    state.cartItems = cartItems
    localStorage.setItem('cartItems', JSON.stringify(cartItems))

    state.cartTotal = cartItems.reduce((carry, item) => {
      let modsTotal = 0
      if (item.modifiers) {
        modsTotal = item.modifiers.reduce((c, modifier) => {
          return c + (Number(modifier.quantity) * Number(modifier.price))
        }, 0)
      }
      return carry + (Number(item.quantity) * Number(item.price)) + modsTotal
    }, 0)
  },
  [types.CLEAR_CART] (state) {
    state.cartItems = []
    state.cartTotal = 0
    localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
  },
  [types.ADD_GRATUITY_TO_CART] (state, data) {
    state.cartGratuity = data
  },
  [types.SET_CART_TAX_RATE] (state, data) {
    state.cartTaxRate = data
  },
  [types.SET_PRODUCT] (state, data) {
    if (!state.selectedProduct || !data) {
      state.selectedProduct = data
    } else {
      Object.keys(data).forEach(function (key) {
        state.selectedProduct[key] = data[key]
      })
    }
  }
}
// actions
export const actions = {
  async fetchProducts ({ commit }) {
    try {
      const { data } = await axios.get('/api/products')
      commit(types.FETCH_ALL_PRODUCTS, { products: data })
    } catch (e) {
      console.log(e)
    }
  },
  async fetchOrders ({ commit }) {
    try {
      const { data } = await axios.get('/api/orders')
      commit(types.FETCH_ALL_ORDERS, { orders: data })
    } catch (e) {
      console.log(e)
    }
  },
  clearCart (context) {
    context.commit(types.CLEAR_CART)
  },
  updateCart (context, data) {
    context.commit(types.UPDATE_CART, data)
  },
  addGratuityToCart (context, data) {
    context.commit(types.ADD_GRATUITY_TO_CART, data)
  },
  setCartTaxRate (context, data) {
    context.commit(types.SET_CART_TAX_RATE, data)
  },
  setProduct (context, data) {
    context.commit(types.SET_PRODUCT, data)
  },
}
